import React, { useEffect, useState } from 'react'
import { Modal, ModalBody, ModalHeader, ModalFooter } from 'reactstrap'
import { useForm } from 'react-hook-form'
import edit from '../../../images/icons/edit.png'
import nuevo from '../../../images/icons/new.png'
import view from '../../../images/icons/view.png'
import borrar from '../../../images/icons/delete.png'
import axios from 'axios'
import { Helmet } from 'react-helmet'
import Moment from 'moment'
import Cookies from 'universal-cookie'
import MaterialTable from 'material-table'

const Clientes = () =>  {

    const baseURL = "http://apiv2.pyrmultimediasac.com/cliente";
    const distURL = "http://apiv2.pyrmultimediasac.com/distrito/listarDistrito";
    const docURL = "http://apiv2.pyrmultimediasac.com/parametro/listarTipoDocumento";
    const cookie = new Cookies();

    const { register, handleSubmit, reset} = useForm()
    const [cliente, setCliente] = useState([])
    const [clienteResponse, setClienteResponse] = useState([])
    const [clienteRequest] = useState({})
    const [distrito, setDistrito] = useState([])
    const [tipoDocumento, setTipoDocumento] = useState([])
    const [mensaje, setMensaje] = useState([])
    const [tipomensaje, setTipoMensaje] = useState([])
    const [modalEditar, setModalEditar] = useState(false)
    const [modalNuevo, setModalNuevo] = useState(false)
    const [modalMostrar, setModalMostrar] = useState(false)
    const [modalEliminar, setModalEliminar] = useState(false)
    const [modalRespuesta, setModalRespuesta] = useState(false)

    const verModalEditar = () => setModalEditar(!modalEditar)
    const verModalNuevo = () => setModalNuevo(!modalNuevo)
    const verModalMostrar = () => setModalMostrar(!modalMostrar)
    const verModalEliminar = () => setModalEliminar(!modalEliminar)
    const vermodalRespuesta = () => setModalRespuesta(!modalRespuesta)

    const columns=[
        {title: 'Número Registro', field: 'NumeroRegistro'},
        {title: 'Nombre', field: 'NombreCliente'},
        {title: 'Teléfono', field: 'TelefonoCliente'},
        {title: 'Correo', field: 'CorreoCliente'},
        {title: 'Fecha de Ingreso', field: 'FechaIngreso', type: 'date'}
    ]

    const getDistritos = async () => {
        await axios.get(distURL)
            .then(response => {
                setDistrito(response.data);
            }).catch(error => {
                console.log(error);
            })
    }

    const getTipoDocumento = async () => {
        await axios.get(docURL)
            .then(response => {
                setTipoDocumento(response.data);
            }).catch(error => {
                console.log(error);
            })
    }

    const listaCliente = async () => {
        await axios.get(baseURL + "/listarCliente")
            .then(response => {
                setCliente(response.data.Resultado);                
            }).catch(error => {
                console.log(error);
            })

    }

    const insertarCliente = async (data) => {
        delete data.IdCliente
        data.UsuarioCreacion = cookie.get('lusuario')
        data.FechaCreacion = Moment().format()
        await axios.post(baseURL + "/insertarCliente", data)
            .then(response => {
                setTipoMensaje(response.data.TipoMensaje)
                setMensaje(response.data.RespuestaExito)
                verModalNuevo()
                vermodalRespuesta()
                listaCliente()
            }).catch(error => {
                setTipoMensaje(error.data.TipoMensaje)
                setMensaje(error.data.RespuestaError)
                console.log(error.data.Respuesta)
                vermodalRespuesta()
            })
    }

    const obtenerCliente = async (data, caso) => {
        clienteRequest.IdCliente = data.IdCliente
        await axios.post(baseURL + "/obtenerCliente", clienteRequest)
            .then(response => {
                setClienteResponse(response.data.Resultado[0])
                if (caso === "ver") {
                    verModalMostrar()
                } else if (caso === "editar") {
                    verModalEditar()
                } else {
                    verModalEliminar()
                }
            }).catch(error => {
                setTipoMensaje(error.data.TipoMensaje)
                setMensaje(error.data.RespuestaError)
                console.log(error.data.Respuesta)
                vermodalRespuesta()
            })
    }

    const actualizarCliente = async (data) => {
        data.UsuarioModificacion = cookie.get('lusuario')
        data.FechaModificacion = Moment().format()
        console.log(data)
        await axios.put(baseURL + "/actualizarCliente", data)
            .then(response => {
                setTipoMensaje(response.data.TipoMensaje)
                setMensaje(response.data.RespuestaExito)
                verModalEditar()
                vermodalRespuesta()
                listaCliente()
            }).catch(error => {
                setTipoMensaje(error.data.TipoMensaje)
                setMensaje(error.data.RespuestaError)
                console.log(error.data.Respuesta)
                vermodalRespuesta()
            })
    }

    const eliminarCliente = async (data) => {
        clienteRequest.UsuarioModificacion = cookie.get('lusuario')
        clienteRequest.FechaModificacion = Moment().format()
        clienteRequest.IdCliente = data.IdCliente
        console.log(clienteRequest)
        await axios.post(baseURL + "/eliminarCliente", clienteRequest)
            .then(response => {
                setTipoMensaje(response.data.TipoMensaje)
                setMensaje(response.data.RespuestaExito)
                verModalEliminar()
                vermodalRespuesta()
                listaCliente()
            }).catch(error => {
                setTipoMensaje(error.data.TipoMensaje)
                setMensaje(error.data.RespuestaError)
                vermodalRespuesta()
                console.log(error)
            })
    }


    useEffect(() => {
        listaCliente();
        getDistritos();
        getTipoDocumento();
    }, []) 
    
    return (
        <>
            <Helmet>
                <title>P&R Multimedia | Lista de Clientes</title>
            </Helmet>
            <div className="content-wrapper">
                <section className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1>Clientes</h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item">Home</li>
                                    <li className="breadcrumb-item active">Lista de clientes</li>
                                </ol>
                            </div>
                        </div>
                    </div>{/* /.container-fluid */}
                </section>
                <section className="content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                {/* /.card */}
                                <div className="card">
                                    {/* /.card-header */}
                                    <div className="card-body">
                                        <a href="javascript:void(0)" onClick={verModalNuevo} title="Nuevo Cliente">
                                            <img src={nuevo}></img>{' '}
                                        </a>
                                        <hr />
                                        <MaterialTable
                                            columns={columns}
                                            data={cliente}
                                            title="Clientes"
                                            actions={[
                                                {
                                                    icon: ()=><img src={edit}></img>,
                                                    tooltip: 'Editar',
                                                    onClick: (event, rowdata)=>obtenerCliente(rowdata,"editar")
                                                },                                                
                                                {
                                                    icon: ()=><img src={view}></img>,
                                                    tooltip: 'Ver',
                                                    onClick: (event, rowdata)=>obtenerCliente(rowdata,"ver")
                                                },
                                                {
                                                    icon: ()=><img src={borrar}></img>,
                                                    tooltip: 'Eliminar',
                                                    onClick: (event, rowdata)=>obtenerCliente(rowdata,"eliminar")
                                                }
                                            ]}
                                            options={{
                                                paging: false,
                                                actionsColumnIndex: -1,
                                                header: { position: 'sticky', top: 0 }
                                            }}
                                            localization={{
                                                header: { actions: 'Acciones' },
                                                toolbar:{ searchTooltip: 'Buscar', searchPlaceholder: 'Buscar' },
                                                pagination:{labelDisplayedRows:'{from}-{to} de {count} filas',labelRowsSelect:'Filas',firstTooltip:'Primera Página',previousTooltip:'Página anterior', lastTooltip:'Última Página', nextTooltip:'Página siguiente'}
                                            }}
                                        /> 
                                    </div>
                                    {/* /.card-body */}
                                </div>
                                {/* /.card */}
                            </div>
                            {/* /.col */}
                        </div>
                        {/* /.row */}
                    </div>
                    {/* /.container-fluid */}
                </section>
            </div>
            {/* Modal Insertar*/}
            <Modal isOpen={modalNuevo}>
                <ModalHeader>Nuevo Cliente</ModalHeader>
                <ModalBody>
                    <form>
                        <div className="mb-3">
                            <label className="form-label">Nombre</label>
                            <input className="form-control" type="text" {...register("NombreCliente", { required: true, value: "" })} />
                            <input className="form-control" type="hidden" {...register("IdCliente", { required: true, value: 0 })} />
                        </div>
                        <div className="mb-3">
                            <label className="form-label">Apellido Paterno</label>
                            <input className="form-control" type="text" {...register("ApellidoPaterno", { required: true, value: "" })} />
                        </div>
                        <div className="mb-3">
                            <label className="form-label">Tipo de Documento de Identidad</label>
                            <select className="form-control" {...register("TipoDocumento", { valueAsNumber: true, value: 0 })}>
                                <option value="0">Seleccione...</option>
                                {tipoDocumento.map(tipodocumento => (
                                    <option key={tipodocumento.IdParametro} value={tipodocumento.IdParametro}>{tipodocumento.NombreParametro}</option>
                                ))}
                            </select>
                        </div>
                        <div className="mb-3">
                            <label className="form-label">Número de documento de identidad</label>
                            <input className="form-control" type="number" {...register("NumeroDocumento", {  value: "" })} />
                        </div>
                        <div className="mb-3">
                            <label className="form-label">Distrito</label>
                            <select className="form-control" {...register("IdDistrito", { required: true, value: 0 })}>
                                <option value="0">Seleccione...</option>
                                {distrito.map(distrito => (
                                    <option key={distrito.IdDistrito} value={distrito.IdDistrito}>{distrito.NombreDistrito}</option>
                                ))}
                            </select>
                        </div>
                        <div className="mb-3">
                            <label className="form-label">Dirección</label>
                            <input className="form-control" {...register("DireccionCliente", { required: true, value: "" })} />
                        </div>
                        <div className="mb-3">
                            <label className="form-label">Número de teléfono</label>
                            <input className="form-control" type="number" maxLength="9" {...register("TelefonoCliente", { value: "" })} />
                        </div>
                        <div className="mb-3">
                            <label className="form-label">Correo electrónico</label>
                            <input className="form-control" type="email" {...register("CorreoCliente", { required: true, pattern: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/, value: "" })} />
                        </div>
                    </form>
                </ModalBody>
                <ModalFooter>
                    <button type="sub" className="btn btn-primary" onClick={handleSubmit(insertarCliente)}>Insertar</button>
                    <button className="btn btn-danger" onClick={verModalNuevo}>Cancelar</button>
                </ModalFooter>
            </Modal>
            {/* Modal Editar*/}
            <Modal isOpen={modalEditar}>
                <ModalHeader>Editar Cliente</ModalHeader>
                <ModalBody>
                    <form>
                        <div className="mb-3">
                            <label className="form-label">Nombre</label>
                            <input className="form-control" {...register("NombreCliente", { required: true, value: clienteResponse && clienteResponse.NombreCliente })} />
                            <input className="form-control" type="hidden" {...register("IdCliente", { required: true, value: clienteResponse && clienteResponse.IdCliente })} />
                        </div>
                        <div className="mb-3">
                            <label className="form-label">Apellido Paterno</label>
                            <input className="form-control" {...register("ApellidoPaterno", { value: clienteResponse && clienteResponse.ApellidoPaterno })} />
                        </div>
                        <div className="mb-3">
                            <label className="form-label">Tipo de Documento de Identidad</label>
                            <select className="form-control" {...register("TipoDocumento", { valueAsNumber: true, value: clienteResponse && clienteResponse.TipoDocumento })}>
                                <option value="0">Seleccione...</option>
                                {tipoDocumento.map(tipodocumento => (
                                    <option key={tipodocumento.IdParametro} value={tipodocumento.IdParametro}>{tipodocumento.NombreParametro}</option>
                                ))}
                            </select>
                        </div>
                        <div className="mb-3">
                            <label className="form-label">Número de documento de identidad</label>
                            <input className="form-control" type="number" {...register("NumeroDocumento", { required: true, value: clienteResponse && clienteResponse.NumeroDocumento })} />
                        </div>
                        <div className="mb-3">
                            <label className="form-label">Distrito</label>
                            <select className="form-control" {...register("IdDistrito", { required: true, value: clienteResponse && clienteResponse.IdDistrito })}>
                                <option value="0">Seleccione...</option>
                                {distrito.map(distrito => (
                                    <option key={distrito.IdDistrito} value={distrito.IdDistrito}>{distrito.NombreDistrito}</option>
                                ))}
                            </select>
                        </div>
                        <div className="mb-3">
                            <label className="form-label">Dirección</label>
                            <input className="form-control" {...register("DireccionCliente", { required: true, value: clienteResponse && clienteResponse.DireccionCliente })} />
                        </div>
                        <div className="mb-3">
                            <label className="form-label">Número de teléfono</label>
                            <input className="form-control" type="number" maxLength="9" {...register("TelefonoCliente", {  value: clienteResponse && clienteResponse.TelefonoCliente })} />
                        </div>
                        <div className="mb-3">
                            <label className="form-label">Correo electrónico</label>
                            <input className="form-control" type="email" {...register("CorreoCliente", { required: true, pattern: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/, value: clienteResponse && clienteResponse.CorreoCliente })} />
                        </div>
                    </form>
                </ModalBody>
                <ModalFooter>
                    <button className="btn btn-primary" onClick={handleSubmit(actualizarCliente)}>Actualizar</button>{' '}
                    <button className="btn btn-danger" onClick={verModalEditar}>Cancelar</button>
                </ModalFooter>
            </Modal>
            {/* Modal Mostrar*/}
            <Modal isOpen={modalMostrar}>
                <ModalHeader>Cliente Nº: {clienteResponse && clienteResponse.IdCliente}</ModalHeader>
                <ModalBody>
                    <form>
                        <div className="mb-3">
                            <label className="form-label">Nombre</label>
                            <input className="form-control" readOnly value={clienteResponse && clienteResponse.NombreCliente} />
                        </div>
                        <div className="mb-3">
                            <label className="form-label">Apellido Paterno</label>
                            <input className="form-control" readOnly value={clienteResponse && clienteResponse.ApellidoPaterno} />
                        </div>
                        <div className="mb-3">
                            <label className="form-label">Tipo de Documento de Identidad</label>
                            <select className="form-control" disabled value={clienteResponse && clienteResponse.TipoDocumento} >
                                <option value="0">Seleccione...</option>
                                {tipoDocumento.map(tipodocumento => (
                                    <option key={tipodocumento.IdParametro} value={tipodocumento.IdParametro}>{tipodocumento.NombreParametro}</option>
                                ))}
                            </select>
                        </div>
                        <div className="mb-3">
                            <label className="form-label">Número de documento de identidad</label>
                            <input className="form-control" type="number" readOnly value={clienteResponse && clienteResponse.NumeroDocumento} />
                        </div>
                        <div className="mb-3">
                            <label className="form-label">Distrito</label>
                            <select className="form-control" disabled value={clienteResponse && clienteResponse.IdDistrito} >
                                <option value="0">Seleccione...</option>
                                {distrito.map(distrito => (
                                    <option key={distrito.IdDistrito} value={distrito.IdDistrito}>{distrito.NombreDistrito}</option>
                                ))}
                            </select>
                        </div>
                        <div className="mb-3">
                            <label className="form-label">Dirección</label>
                            <input className="form-control" readOnly value={clienteResponse && clienteResponse.DireccionCliente} />
                        </div>
                        <div className="mb-3">
                            <label className="form-label">Número de teléfono</label>
                            <input className="form-control" type="number" maxLength="9" readOnly value={clienteResponse && clienteResponse.TelefonoCliente} />
                        </div>
                        <div className="mb-3">
                            <label className="form-label">Correo electrónico</label>
                            <input className="form-control" type="email" readOnly value={clienteResponse && clienteResponse.CorreoCliente} />
                        </div>
                    </form>
                </ModalBody>
                <ModalFooter>
                    <button className="btn btn-success" onClick={verModalMostrar}>Cerrar</button>
                </ModalFooter>
            </Modal>
            {/* Modal Eliminar*/}
            <Modal isOpen={modalEliminar}>
                <ModalHeader>
                    Eliminar Cliente
                </ModalHeader>
                <ModalBody>
                    <form>
                        <input className="form-control" type="hidden" {...register("IdCliente", { required: true, value: clienteResponse && clienteResponse.IdCliente })} />
                    </form>
                    Está seguro de eliminar el registro?
                </ModalBody>
                <ModalFooter>
                    <button className="btn btn-warning" onClick={handleSubmit(eliminarCliente)}>Sí</button>
                    <button className="btn btn-danger" onClick={verModalEliminar}>Cerrar</button>
                </ModalFooter>
            </Modal>
            {/* Modal Respuesta*/}
            <Modal isOpen={modalRespuesta}>
                <ModalHeader>{tipomensaje}</ModalHeader>
                <ModalBody>{mensaje}</ModalBody>
                <ModalFooter>
                    <button className="btn btn-success" onClick={vermodalRespuesta}>Cerrar</button>
                </ModalFooter>
            </Modal>            
        </>
    )
}

export default Clientes;