import SignIn from "./LoginComponents/SignIn";

function Login() {
    return (
        <div class="wrapper">
          <SignIn />
        </div>
      );
    
}

export default Login;