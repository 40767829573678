import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import Cookies from 'universal-cookie'
import { Modal, ModalBody, ModalHeader, ModalFooter } from 'reactstrap'

const Header = () => {

    const cookie = new Cookies()

    const [modalRespuesta, setModalRespuesta] = useState(false)
    const vermodalRespuesta = () => setModalRespuesta(!modalRespuesta)

    const cerrarSesion = () => {
        cookie.remove('lusuario', { path: "/" })
        cookie.remove('nempleado', { path: "/" })
        window.location.href = '/'
    }

    useEffect(() => {
        if (!cookie.get('nempleado')) {
            window.location.href = '/'
        }
    }, [])

    return (
        <>
            <nav className="main-header navbar navbar-expand navbar-white navbar-light">
                {/* Left navbar links */}
                <ul className="navbar-nav">
                    <li className="nav-item">
                        <div className="nav-link" data-widget="pushmenu" role="button"><i className="fas fa-bars" /></div>
                    </li>
                    <li className="nav-item d-none d-sm-inline-block">
                        <Link to="/home/inicio" className="nav-link">Home</Link>
                    </li>
                </ul>                
                {/* Right navbar links */}
                <ul className="navbar-nav ml-auto">
                    <li className="nav-item">
                        <button className="btn btn-danger" onClick={vermodalRespuesta}>Cerrar Sesión</button>
                    </li>
                </ul>
            </nav>
            {/* Modal Respuesta*/}
            <Modal isOpen={modalRespuesta}>
                <ModalHeader>
                    Cerrar Sesión
                </ModalHeader>
                <ModalBody>
                    ¿Desea Cerrar su Sesión?
                </ModalBody>
                <ModalFooter>
                    <button className="btn btn-primary" onClick={cerrarSesion}>Sí</button>
                    <button className="btn btn-danger" onClick={vermodalRespuesta}>Cancelar</button>
                </ModalFooter>
            </Modal>
        </>
    )
}

export default Header;