import React from 'react'
import { Helmet } from 'react-helmet'
import Logo from '../../../images/logo.jpg'


const Dashboard = () => {
    return (
        <>
            <Helmet>
                <title>P&R Multimedia | Inicio</title>
            </Helmet>
            <div className="hold-transition login-page">
                <div className="login-box">
                    {/* /.login-logo */}
                    <div className="card card-outline card-primary">
                        <div className="card-header text-center">
                            <img src={Logo} className="card-img-top" alt=""></img>
                        </div>
                        <div className="card-body">
                            <p className="login-box-msg"><strong>Bienvenido al Sistema de Órdenes de Servicio</strong></p>
                        </div>
                        {/* /.card-body */}
                    </div>
                    {/* /.card */}
                </div>
            </div>
            {/* /.content-wrapper */}
        </>
    )
}

export default Dashboard;