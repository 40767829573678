import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet'

import { useForm } from 'react-hook-form'
import axios from 'axios'
import Logo from '../../../images/logo.jpg'
import Cookies from 'universal-cookie'

const SignIn = () => {    

    const { register, handleSubmit } = useForm()

    const baseURL = "http://apiv2.pyrmultimediasac.com/usuario";
    const cookie = new Cookies()
    
    useEffect(() => {
        if (cookie.get('nempleado')) {
            window.location.href='./home'
        }
    }, [])

    const obtenerUsuario = async(data) => {
        await axios.post(baseURL+'/obtenerUsuario',data)
            .then(response=>{
                return response.data
            })
            .then(response=>{
                const res = response.Resultado[0]
                if (response.Resultado.length>0) {
                    cookie.set('lusuario',res.LoginUsuario,{path:"/"});
                    cookie.set('nempleado',res.NombreEmpleado,{path:"/"});
                    alert(`Bienvenido ${cookie.get('nempleado')}`)
                    window.location.href="./home"
                }else{
                    alert('El usuario o la contraseña no son correctos')
                }
            })
            .catch(error=>{
                console.log(error)
            })
    }
    
    return (
        <>
            <Helmet>
                <title>P&R Multimedia | Login</title>
            </Helmet>
            <div className="hold-transition login-page">
                <div className="login-box">
                    {/* /.login-logo */}
                    <div className="card card-outline card-primary">
                        <div className="card-header text-center">
                            <img src={Logo} className="card-img-top" alt=""></img>
                        </div>
                        <div className="card-body">
                            <p className="login-box-msg">Loguear para empezar tu sesión</p>
                            <form>
                                <div className="input-group mb-3">
                                    <input type="text" className="form-control" placeholder="Nombre de usuario" {...register("LoginUsuario", { required: true, value: "" })} />                                    
                                    <div className="input-group-append">
                                        <div className="input-group-text">
                                            <span className="fas fa-envelope" />
                                        </div>
                                    </div>
                                </div>
                                <div className="input-group mb-3">
                                    <input type="password" className="form-control" placeholder="Contraseña" {...register("PassUsuario", { required: true, value: "" })} />
                                    <div className="input-group-append">
                                        <div className="input-group-text">
                                            <span className="fas fa-lock" />
                                        </div>
                                    </div>
                                </div>
                            </form>
                            <div className="row">
                                <div className="col-4">
                                    <button type="sub" className="btn btn-primary btn-block" onClick={handleSubmit(obtenerUsuario)} >Iniciar Sesión</button>
                                </div>
                            </div>
                        </div>
                        {/* /.card-body */}
                    </div>
                    {/* /.card */}
                </div>
            </div>
        </>
    )
}

export default SignIn;