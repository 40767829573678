import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import Dashboard from '../components/home/HomeComponents/Dashboard'
import Clientes from '../components/home/HomeComponents/Clientes'
import Solicitudes from '../components/home/HomeComponents/Solicitudes'
import Empleados from '../components/home/HomeComponents/Empleados'
import Servicios from '../components/home/HomeComponents/Servicios'
import Usuarios from '../components/home/HomeComponents/Usuarios'
import Menu from '../components/home/Menu'

const MenuRoutes = () => {
    return(
        <>
            <Menu />
            <div>
                <Switch>
                    <Route exact path="/home/inicio" component={Dashboard} />
                    <Route exact path="/home/solicitudes" component={Solicitudes} />
                    <Route exact path="/home/empleados" component={Empleados} />
                    <Route exact path="/home/usuarios" component={Usuarios} />
                    <Route exact path="/home/clientes" component={Clientes} />
                    <Route exact path="/home/servicios" component={Servicios} />

                    <Redirect to="/home/inicio" />
                </Switch>
            </div>
        </>
    )    
}

export default MenuRoutes;