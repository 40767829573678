import React, { useEffect, useState } from 'react'
import { Modal, ModalBody, ModalHeader, ModalFooter } from 'reactstrap'
import { useForm } from 'react-hook-form'
import edit from '../../../images/icons/edit.png'
import nuevo from '../../../images/icons/new.png'
import view from '../../../images/icons/view.png'
import borrar from '../../../images/icons/delete.png'
import axios from 'axios'
import { Helmet } from 'react-helmet'
import Moment from 'moment'
import Cookies from 'universal-cookie'
import MaterialTable from 'material-table'

const Servicios = () => {

    const baseURL = "http://apiv2.pyrmultimediasac.com/servicio";
    const cookie = new Cookies();
    
    const { register, handleSubmit} = useForm()
    const [servicio, setServicio] = useState([])
    const [servicioResponse, setServicioResponse] = useState([])
    const [servicioRequest] = useState({})
    const [mensaje, setMensaje] = useState([])
    const [tipomensaje, setTipoMensaje] = useState([])
    const [modalEditar, setModalEditar] = useState(false)
    const [modalNuevo, setModalNuevo] = useState(false)
    const [modalMostrar, setModalMostrar] = useState(false)
    const [modalEliminar, setModalEliminar] = useState(false)
    const [modalRespuesta, setModalRespuesta] = useState(false)

    const verModalEditar = () => setModalEditar(!modalEditar)
    const verModalNuevo = () => setModalNuevo(!modalNuevo)
    const verModalMostrar = () => setModalMostrar(!modalMostrar)
    const verModalEliminar = () => setModalEliminar(!modalEliminar)
    const vermodalRespuesta = () => setModalRespuesta(!modalRespuesta)

    const columns=[
        {title: 'Número Registro', field: 'NumeroRegistro'},
        {title: 'Nombre de Servicio', field: 'NombreServicio'},        
        {title: 'Fecha de Creación', field: 'FechaCreacion', type: 'date'}
    ]

    const listaServicios = async () => {
        await axios.get(baseURL + "/listarServicio")
            .then(response => {
                setServicio(response.data.Resultado);
            }).catch(error => {
                console.log(error);
            })

    }

    const insertarServicio = async (data) => {
        delete data.IdServicio
        data.UsuarioCreacion = cookie.get('lusuario')
        data.FechaCreacion = Moment().format()        
        await axios.post(baseURL + "/insertarServicio", data)
            .then(response => {
                setTipoMensaje(response.data.TipoMensaje)
                setMensaje(response.data.RespuestaExito)
                verModalNuevo()
                vermodalRespuesta()
                listaServicios()
            }).catch(error => {
                setTipoMensaje(error.data.TipoMensaje)
                setMensaje(error.data.RespuestaError)
                console.log(error.data.Respuesta)
                vermodalRespuesta()
            })
    }

    const obtenerServicio = async (data, caso) => {
        servicioRequest.IdServicio = data.IdServicio
        await axios.post(baseURL + "/obtenerServicio", servicioRequest)
            .then(response => {
                setServicioResponse(response.data.Resultado[0])
                if (caso === "ver") {
                    verModalMostrar()
                } else if (caso === "editar") {
                    verModalEditar()
                } else {
                    verModalEliminar()
                }
            }).catch(error => {
                setTipoMensaje(error.data.TipoMensaje)
                setMensaje(error.data.RespuestaError)
                console.log(error.data.Respuesta)
                vermodalRespuesta()
            })
    }

    const actualizarServicio = async (data) => {
        data.UsuarioModificacion = cookie.get('lusuario')
        data.FechaModificacion = Moment().format()
        await axios.put(baseURL + "/actualizarServicio", data)
            .then(response => {
                setTipoMensaje(response.data.TipoMensaje)
                setMensaje(response.data.RespuestaExito)
                verModalEditar()
                vermodalRespuesta()
                listaServicios()
            }).catch(error => {
                setTipoMensaje(error.data.TipoMensaje)
                setMensaje(error.data.RespuestaError)
                console.log(error.data.Respuesta)
                vermodalRespuesta()
            })
    }

    const eliminarServicio = async (data) => {
        servicioRequest.UsuarioModificacion = cookie.get('lusuario')
        servicioRequest.FechaModificacion = Moment().format()
        servicioRequest.IdServicio = data.IdServicio
        console.log(servicioRequest)
        await axios.post(baseURL + "/eliminarServicio", servicioRequest)
            .then(response => {
                setTipoMensaje(response.data.TipoMensaje)
                setMensaje(response.data.RespuestaExito)
                verModalEliminar()
                vermodalRespuesta()
                listaServicios()
            }).catch(error => {
                setTipoMensaje(error.data.TipoMensaje)
                setMensaje(error.data.RespuestaError)
                vermodalRespuesta()
            })
    }


    useEffect(() => {
        listaServicios();
    }, [])

    return (
        <>
            <Helmet>
                <title>P&R Multimedia | Lista de Servicios</title>
            </Helmet>
            <div className="content-wrapper">
                <section className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1>Servicios</h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item">Home</li>
                                    <li className="breadcrumb-item active">Lista de servicios</li>
                                </ol>
                            </div>
                        </div>
                    </div>{/* /.container-fluid */}
                </section>
                <section className="content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                {/* /.card */}
                                <div className="card">
                                    {/* /.card-header */}
                                    <div className="card-body">
                                        <a href="javascript:void(0)" onClick={verModalNuevo} title="Nuevo Servicio">
                                            <img src={nuevo}></img>{' '}
                                        </a>
                                        <hr />
                                        <MaterialTable
                                            columns={columns}
                                            data={servicio}
                                            title="Usuarios"
                                            actions={[
                                                {
                                                    icon: ()=><img src={edit}></img>,
                                                    tooltip: 'Editar',
                                                    onClick: (event, rowdata)=>obtenerServicio(rowdata,"editar")
                                                },                                                
                                                {
                                                    icon: ()=><img src={view}></img>,
                                                    tooltip: 'Ver',
                                                    onClick: (event, rowdata)=>obtenerServicio(rowdata,"ver")
                                                },
                                                {
                                                    icon: ()=><img src={borrar}></img>,
                                                    tooltip: 'Eliminar',
                                                    onClick: (event, rowdata)=>obtenerServicio(rowdata,"eliminar")
                                                }
                                            ]}
                                            options={{
                                                paging: false,
                                                actionsColumnIndex: -1,
                                                header: { position: 'sticky', top: 0 }
                                            }}
                                            localization={{
                                                header: { actions: 'Acciones' },
                                                toolbar:{ searchTooltip: 'Buscar', searchPlaceholder: 'Buscar' },
                                                pagination:{labelDisplayedRows:'{from}-{to} de {count} filas',labelRowsSelect:'Filas',firstTooltip:'Primera Página',previousTooltip:'Página anterior', lastTooltip:'Última Página', nextTooltip:'Página siguiente'}
                                            }}
                                        />   
                                    </div>
                                    {/* /.card-body */}
                                </div>
                                {/* /.card */}
                            </div>
                            {/* /.col */}
                        </div>
                        {/* /.row */}
                    </div>
                    {/* /.container-fluid */}
                </section>
            </div>
            {/* Modal Insertar*/}
            <Modal isOpen={modalNuevo}>
                <ModalHeader>Nuevo Servicio</ModalHeader>
                <ModalBody>
                    <form>
                        <div className="mb-3">
                            <label className="form-label">Nombre de Servicio</label>
                            <input className="form-control" type="text" {...register("NombreServicio", { required: true })} />
                            <input className="form-control" type="hidden" {...register("IdServicio", { required: true, value: 0 })} />
                        </div>
                    </form>
                </ModalBody>
                <ModalFooter>
                    <button type="sub" className="btn btn-primary" onClick={handleSubmit(insertarServicio)}>Insertar</button>
                    <button className="btn btn-danger" onClick={verModalNuevo}>Cancelar</button>
                </ModalFooter>
            </Modal>
            {/* Modal Editar*/}
            <Modal isOpen={modalEditar}>
                <ModalHeader>Editar Servicio</ModalHeader>
                <ModalBody>
                    <form>
                        <div className="mb-3">
                            <label className="form-label">Nombre de Servicio</label>
                            <input className="form-control" {...register("NombreServicio", { required: true, value: servicioResponse && servicioResponse.NombreServicio })} />
                            <input className="form-control" type="hidden" {...register("IdServicio", { required: true, value: servicioResponse && servicioResponse.IdServicio })} />
                        </div>
                    </form>
                </ModalBody>
                <ModalFooter>
                    <button className="btn btn-primary" onClick={handleSubmit(actualizarServicio)}>Actualizar</button>{' '}
                    <button className="btn btn-danger" onClick={verModalEditar}>Cancelar</button>
                </ModalFooter>
            </Modal>
            {/* Modal Mostrar*/}
            <Modal isOpen={modalMostrar}>
                <ModalHeader>Servicio Nº: {servicioResponse && servicioResponse.IdServicio}</ModalHeader>
                <ModalBody>
                    <form>
                        <div className="mb-3">
                            <label className="form-label">Nombre de Servicio</label>
                            <input className="form-control" readOnly value={servicioResponse && servicioResponse.NombreServicio} />
                        </div>
                    </form>
                </ModalBody>
                <ModalFooter>
                    <button className="btn btn-success" onClick={verModalMostrar}>Cerrar</button>
                </ModalFooter>
            </Modal>
            {/* Modal Eliminar*/}
            <Modal isOpen={modalEliminar}>
                <ModalHeader>
                    Eliminar Servicio
                </ModalHeader>
                <ModalBody>
                    <form>
                        <input className="form-control" type="hidden" {...register("IdServicio", { required: true, value: servicioResponse && servicioResponse.IdServicio })} />
                    </form>
                    Está seguro de eliminar el registro?
                </ModalBody>
                <ModalFooter>
                    <button className="btn btn-warning" onClick={handleSubmit(eliminarServicio)}>Sí</button>
                    <button className="btn btn-danger" onClick={verModalEliminar}>Cerrar</button>
                </ModalFooter>
            </Modal>
            {/* Modal Respuesta*/}
            <Modal isOpen={modalRespuesta}>
                <ModalHeader>{tipomensaje}</ModalHeader>
                <ModalBody>{mensaje}</ModalBody>
                <ModalFooter>
                    <button className="btn btn-success" onClick={vermodalRespuesta}>Cerrar</button>
                </ModalFooter>
            </Modal>
        </>
    )
}

export default Servicios