import React from "react";
import AppRouter from "./routes/AppRoutes";

const App = ()=> {
  return (
    <div>
      <AppRouter />
    </div>
  );
}

export default App;
