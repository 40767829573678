import React, { useEffect, useState } from 'react'
import { Modal, ModalBody, ModalHeader, ModalFooter } from 'reactstrap'
import { useForm } from 'react-hook-form'
import edit from '../../../images/icons/edit.png'
import nuevo from '../../../images/icons/new.png'
import view from '../../../images/icons/view.png'
import borrar from '../../../images/icons/delete.png'
import axios from 'axios'
import { Helmet } from 'react-helmet'
import Moment from 'moment'
import Cookies from 'universal-cookie'
import MaterialTable from 'material-table'

const Solicitudes = () => {

    //#region API URL
    const baseURL = "http://apiv2.pyrmultimediasac.com/solicitud";
    const cliURL = "http://apiv2.pyrmultimediasac.com/cliente/listarCliente";
    const servURL = "http://apiv2.pyrmultimediasac.com/servicio/listarServicio";
    const empURL = "http://apiv2.pyrmultimediasac.com/empleado/listarEmpleado";
    const taURL = "http://apiv2.pyrmultimediasac.com/parametro/listarTipoAtencion";
    const esolURL = "http://apiv2.pyrmultimediasac.com/parametro/listarEstadoSolicitud";
    const eatURL = "http://apiv2.pyrmultimediasac.com/parametro/listarEstadoAtencion";
    //#endregion

    const cookie = new Cookies()

    const { register, handleSubmit, reset, formState: { errors } } = useForm()
    const [solicitud, setSolicitud] = useState([])
    const [solicitudResponse, setSolicitudResponse] = useState([])
    const [solicitudRequest] = useState({})
    const [cliente, setCliente] = useState([])
    const [servicio, setServicio] = useState([])
    const [empleado, setEmpleado] = useState([])
    const [tipoAtencion, setTipoAtencion] = useState([])
    const [estadoSolicitud, setEstadoSolicitud] = useState([])
    const [estadoAtencion, setEstadoAtencion] = useState([])

    const [mensaje, setMensaje] = useState([])
    const [tipomensaje, setTipoMensaje] = useState([])
    const [modalEditar, setModalEditar] = useState(false)
    const [modalNuevo, setModalNuevo] = useState(false)
    const [modalMostrar, setModalMostrar] = useState(false)
    const [modalEliminar, setModalEliminar] = useState(false)
    const [modalRespuesta, setModalRespuesta] = useState(false)

    const verModalEditar = () => setModalEditar(!modalEditar)
    const verModalNuevo = () => setModalNuevo(!modalNuevo)
    const verModalMostrar = () => setModalMostrar(!modalMostrar)
    const verModalEliminar = () => setModalEliminar(!modalEliminar)
    const vermodalRespuesta = () => setModalRespuesta(!modalRespuesta)

    const columns=[
        {title: 'Número Registro', field: 'NumeroRegistro'},
        {title: 'Nombre de Cliente', field: 'NombreCliente'},
        {title: 'Tipo de Atencion', field: 'TipoAtencion'},
        {title: 'Nombre de Servicio', field: 'NombreServicio'},
        {title: 'Estado de Solicitud', field: 'EstadoSolicitud'},
        {title: 'Empleado Asignado', field: 'EmpleadoAsignado'},
        {title: 'Estado de Atención', field: 'EstadoAtencion'},
        {title: 'Fecha de Atención', field: 'FechaAtencion', type: 'date'}
    ]

    //#region listas
    const getClientes = async () => {
        await axios.get(cliURL)
            .then(response => {
                setCliente(response.data.Resultado);
            }).catch(error => {
                console.log(error);
            })
    }

    const getServicio = async () => {
        await axios.get(servURL)
            .then(response => {
                setServicio(response.data.Resultado);
            }).catch(error => {
                console.log(error);
            })
    }

    const getEmpleado = async () => {
        await axios.get(empURL)
            .then(response => {
                setEmpleado(response.data.Resultado);
            }).catch(error => {
                console.log(error);
            })
    }

    const getTipoAtencion = async () => {
        await axios.get(taURL)
            .then(response => {
                setTipoAtencion(response.data);
            }).catch(error => {
                console.log(error);
            })
    }

    const getEstadoSolicitud = async () => {
        await axios.get(esolURL)
            .then(response => {
                setEstadoSolicitud(response.data);
            }).catch(error => {
                console.log(error);
            })
    }

    const getEstadoAtencion = async () => {
        await axios.get(eatURL)
            .then(response => {
                setEstadoAtencion(response.data);
            }).catch(error => {
                console.log(error);
            })
    }
    //#endregion

    //#region Mantenimientos
    const listaSolicitud = async () => {
        await axios.get(baseURL + "/listarSolicitud")
            .then(response => {
                setSolicitud(response.data.Resultado);
            }).catch(error => {
                console.log(error);
            })

    }

    const insertarSolicitud = async (data) => {
        delete data.IdSolicitud
        data.UsuarioCreacion = cookie.get('lusuario')
        data.FechaCreacion = Moment().format()
        await axios.post(baseURL + "/insertarSolicitud", data)
            .then(response => {
                setTipoMensaje(response.data.TipoMensaje)
                setMensaje(response.data.RespuestaExito)
                verModalNuevo()
                vermodalRespuesta()
                listaSolicitud()
            }).catch(error => {
                setTipoMensaje(error.data.TipoMensaje)
                setMensaje(error.data.RespuestaError)
                console.log(error.data.Respuesta)
                vermodalRespuesta()
            })
    }

    const obtenerSolicitud = async (data, caso) => {
        solicitudRequest.IdSolicitud = data.IdSolicitud
        await axios.post(baseURL + "/obtenerSolicitud", solicitudRequest)
            .then(response => {
                console.log(response)
                setSolicitudResponse(response.data.Resultado[0])
                if (caso === "ver") {
                    verModalMostrar()
                } else if (caso === "editar") {
                    verModalEditar()
                } else {
                    verModalEliminar()
                }
            }).catch(error => {
                setTipoMensaje(error.data.TipoMensaje)
                setMensaje(error.data.RespuestaError)
                console.log(error.data.Respuesta)
                vermodalRespuesta()
            })
    }

    const actualizarSolicitud = async (data) => {
        data.UsuarioModificacion = cookie.get('lusuario')
        data.FechaModificacion = Moment().format()
        console.log(data)
        await axios.put(baseURL + "/actualizarSolicitud", data)
            .then(response => {
                setTipoMensaje(response.data.TipoMensaje)
                setMensaje(response.data.RespuestaExito)
                verModalEditar()
                vermodalRespuesta()
                listaSolicitud()
            }).catch(error => {
                setTipoMensaje(error.data.TipoMensaje)
                setMensaje(error.data.RespuestaError)
                console.log(error.data.Respuesta)
                vermodalRespuesta()
            })
    }

    const eliminarSolicitud = async (data) => {
        solicitudRequest.UsuarioModificacion = cookie.get('lusuario')
        solicitudRequest.FechaModificacion = Moment().format()
        solicitudRequest.IdEmpleado = data.IdEmpleado
        console.log(solicitudRequest)
        await axios.post(baseURL + "/eliminarSolicitud", solicitudRequest)
            .then(response => {
                setTipoMensaje(response.data.TipoMensaje)
                setMensaje(response.data.RespuestaExito)
                verModalEliminar()
                vermodalRespuesta()
                listaSolicitud()
            }).catch(error => {
                setTipoMensaje(error.data.TipoMensaje)
                setMensaje(error.data.RespuestaError)
                vermodalRespuesta()
            })
    }

    //#endregion

    useEffect(() => {
        getClientes()
        getEmpleado()
        getServicio()
        getTipoAtencion()
        getEstadoSolicitud()
        getEstadoAtencion()
        listaSolicitud()
    }, [])

    return (
        <>
            <Helmet>
                <title>P&R Multimedia | Lista de Solicitudes</title>
            </Helmet>
            <div className="content-wrapper">
                <section className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1>Solicitudes</h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item">Home</li>
                                    <li className="breadcrumb-item active">Lista de solicitudes</li>
                                </ol>
                            </div>
                        </div>
                    </div>{/* /.container-fluid */}
                </section>
                <section className="content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                {/* /.card */}
                                <div className="card">
                                    {/* /.card-header */}
                                    <div className="card-body">
                                        <a href="javascript:void(0)" onClick={verModalNuevo} title="Nueva Solicitud">
                                            <img src={nuevo}></img>{' '}
                                        </a>
                                        <hr />
                                        <MaterialTable
                                            columns={columns}
                                            data={solicitud}
                                            title="Usuarios"
                                            actions={[
                                                {
                                                    icon: ()=><img src={edit}></img>,
                                                    tooltip: 'Editar',
                                                    onClick: (event, rowdata)=>obtenerSolicitud(rowdata,"editar")
                                                },                                                
                                                {
                                                    icon: ()=><img src={view}></img>,
                                                    tooltip: 'Ver',
                                                    onClick: (event, rowdata)=>obtenerSolicitud(rowdata,"ver")
                                                },
                                                {
                                                    icon: ()=><img src={borrar}></img>,
                                                    tooltip: 'Eliminar',
                                                    onClick: (event, rowdata)=>obtenerSolicitud(rowdata,"eliminar")
                                                }
                                            ]}
                                            options={{
                                                paging: false,
                                                actionsColumnIndex: -1,
                                                header: { position: 'sticky', top: 0 }
                                            }}
                                            localization={{
                                                header: { actions: 'Acciones' },
                                                toolbar:{ searchTooltip: 'Buscar', searchPlaceholder: 'Buscar' },
                                                pagination:{labelDisplayedRows:'{from}-{to} de {count} filas',labelRowsSelect:'Filas',firstTooltip:'Primera Página',previousTooltip:'Página anterior', lastTooltip:'Última Página', nextTooltip:'Página siguiente'}
                                            }}
                                        /> 
                                    </div>
                                    {/* /.card-body */}
                                </div>
                                {/* /.card */}
                            </div>
                            {/* /.col */}
                        </div>
                        {/* /.row */}
                    </div>
                    {/* /.container-fluid */}
                </section>
            </div>
            {/* Modal Insertar*/}
            <Modal isOpen={modalNuevo}>
                <ModalHeader>Nueva Solicitud</ModalHeader>
                <ModalBody>
                    <form>
                        <div className="mb-3">
                            <input className="form-control" type="hidden" {...register("IdSolicitud", { required: true, value: 0 })} />
                            <label className="form-label">Cliente</label>
                            <select className="form-control" {...register("IdCliente", { required: true, value: 0 })}>
                                <option value="0">Seleccione...</option>
                                {cliente.map(cliente => (
                                    <option key={cliente.IdCliente} value={cliente.IdCliente}>{cliente.NombreCliente}</option>
                                ))}
                            </select>
                        </div>
                        <div className="mb-3">
                            <label className="form-label">Tipo de Atención</label>
                            <select className="form-control" {...register("TipoAtencion", { required: true, value: 0 })}>
                                <option value="0">Seleccione...</option>
                                {tipoAtencion.map(tipoAtencion => (
                                    <option key={tipoAtencion.IdParametro} value={tipoAtencion.IdParametro}>{tipoAtencion.NombreParametro}</option>
                                ))}
                            </select>
                        </div>
                        <div className="mb-3">
                            <label className="form-label">Tipo de Servicio</label>
                            <select className="form-control" {...register("IdServicio", { required: true, value: 0 })}>
                                <option value="0">Seleccione...</option>
                                {servicio.map(servicio => (
                                    <option key={servicio.IdServicio} value={servicio.IdServicio}>{servicio.NombreServicio}</option>
                                ))}
                            </select>
                        </div>
                        <div className="mb-3">                            
                            <label className="form-label">Descripción de Solicitud</label>
                            <textarea className="form-control" type="textarea" {...register("DescripcionSolicitud", { required: true, value: "" })} />
                            {errors.DescripcionSolicitud && <span className="text-danger">La descripción es requerida</span>}
                        </div>
                        <div className="mb-3">
                        <label className="form-label">Estado de la Solicitud</label>
                            <select className="form-control" {...register("EstadoSolicitud", { required: true, value: 0 })}>
                                <option value="0">Seleccione...</option>
                                {estadoSolicitud.map(estadoSolicitud => (
                                    <option key={estadoSolicitud.IdParametro} value={estadoSolicitud.IdParametro}>{estadoSolicitud.NombreParametro}</option>
                                ))}
                            </select>
                        </div>
                        <div className="mb-3">
                            <label className="form-label">Asignar Empleado</label>
                            <select className="form-control" {...register("IdEmpleado", { required: true, value: 0 })}>
                                <option value="0">Seleccione...</option>
                                {empleado.map(empleado => (
                                    <option key={empleado.IdEmpleado} value={empleado.IdEmpleado}>{empleado.Nombre} {empleado.Apellidos}</option>
                                ))}
                            </select>
                        </div>
                        <div className="mb-3">
                            <label className="form-label">Fecha de Atención</label>
                            <input className="form-control" type="date" {...register("FechaAtencion", { required: true, value: "" })} />
                            {errors.FechaAtencion && <span className="text-danger">La fecha de atención es requerida</span>}
                        </div>
                        <div className="mb-3">
                        <label className="form-label">Estado de Atención</label>
                            <select className="form-control" {...register("EstadoAtencion", { required: true, value: 0 })}>
                                <option value="0">Seleccione...</option>
                                {estadoAtencion.map(estadoAtencion => (
                                    <option key={estadoAtencion.IdParametro} value={estadoAtencion.IdParametro}>{estadoAtencion.NombreParametro}</option>
                                ))}
                            </select>
                        </div>
                    </form>
                </ModalBody>
                <ModalFooter>
                    <button type="sub" className="btn btn-primary" onClick={handleSubmit(insertarSolicitud)}>Insertar</button>
                    <button className="btn btn-danger" onClick={verModalNuevo}>Cancelar</button>
                </ModalFooter>
            </Modal>
            {/*Modal Editar */}
            <Modal isOpen={modalEditar}>
                <ModalHeader>Editar Solicitud</ModalHeader>
                <ModalBody>
                    <form>
                        <div className="mb-3">
                            <input className="form-control" type="hidden" {...register("IdSolicitud", { required: true, value: solicitudResponse && solicitudResponse.IdSolicitud })} />
                            <label className="form-label">Cliente</label>
                            <select className="form-control" {...register("IdCliente", { required: true, value: solicitudResponse && solicitudResponse.IdCliente })}>
                                <option value="0">Seleccione...</option>
                                {cliente.map(cliente => (
                                    <option key={cliente.IdCliente} value={cliente.IdCliente}>{cliente.NombreCliente}</option>
                                ))}
                            </select>
                        </div>
                        <div className="mb-3">
                            <label className="form-label">Tipo de Atención</label>
                            <select className="form-control" {...register("TipoAtencion", { required: true, value: solicitudResponse && solicitudResponse.TipoAtencion})}>
                                <option value="0">Seleccione...</option>
                                {tipoAtencion.map(tipoAtencion => (
                                    <option key={tipoAtencion.IdParametro} value={tipoAtencion.IdParametro}>{tipoAtencion.NombreParametro}</option>
                                ))}
                            </select>
                        </div>
                        <div className="mb-3">
                            <label className="form-label">Tipo de Servicio</label>
                            <select className="form-control" {...register("IdServicio", { required: true, value: solicitudResponse && solicitudResponse.IdServicio })}>
                                <option value="0">Seleccione...</option>
                                {servicio.map(servicio => (
                                    <option key={servicio.IdServicio} value={servicio.IdServicio}>{servicio.NombreServicio}</option>
                                ))}
                            </select>
                        </div>
                        <div className="mb-3">                            
                            <label className="form-label">Descripción de Solicitud</label>
                            <textarea className="form-control" type="textarea" {...register("DescripcionSolicitud", { required: true, value: solicitudResponse && solicitudResponse.DescripcionSolicitud })} />
                            {errors.DescripcionSolicitud && <span className="text-danger">La descripción es requerida</span>}
                        </div>
                        <div className="mb-3">
                        <label className="form-label">Estado de la Solicitud</label>
                            <select className="form-control" {...register("EstadoSolicitud", { required: true, value: solicitudResponse && solicitudResponse.EstadoSolicitud })}>
                                <option value="0">Seleccione...</option>
                                {estadoSolicitud.map(estadoSolicitud => (
                                    <option key={estadoSolicitud.IdParametro} value={estadoSolicitud.IdParametro}>{estadoSolicitud.NombreParametro}</option>
                                ))}
                            </select>
                        </div>
                        <div className="mb-3">
                            <label className="form-label">Asignar Empleado</label>
                            <select className="form-control" {...register("IdEmpleado", { required: true, value: solicitudResponse && solicitudResponse.IdEmpleado })}>
                                <option value="0">Seleccione...</option>
                                {empleado.map(empleado => (
                                    <option key={empleado.IdEmpleado} value={empleado.IdEmpleado}>{empleado.Nombre} {empleado.Apellidos}</option>
                                ))}
                            </select>
                        </div>
                        <div className="mb-3">
                            <label className="form-label">Fecha de Atención</label>
                            <input className="form-control" type="date" {...register("FechaAtencion", { required: true, value: solicitudResponse && solicitudResponse.FechaAtencion })} />
                            {errors.FechaAtencion && <span className="text-danger">La fecha de atención es requerida</span>}
                        </div>
                        <div className="mb-3">
                        <label className="form-label">Estado de Atención</label>
                            <select className="form-control" {...register("EstadoAtencion", { required: true, value: solicitudResponse && solicitudResponse.EstadoAtencion })}>
                                <option value="0">Seleccione...</option>
                                {estadoAtencion.map(estadoAtencion => (
                                    <option key={estadoAtencion.IdParametro} value={estadoAtencion.IdParametro}>{estadoAtencion.NombreParametro}</option>
                                ))}
                            </select>
                        </div>
                    </form>
                </ModalBody>
                <ModalFooter>
                    <button type="sub" className="btn btn-primary" onClick={handleSubmit(actualizarSolicitud)}>Actualizar</button>
                    <button className="btn btn-danger" onClick={verModalEditar}>Cancelar</button>
                </ModalFooter>
            </Modal>
            {/* Modal Mostrar*/}
            <Modal isOpen={modalMostrar}>
                <ModalHeader>Solicitud Nº: {solicitudResponse && solicitudResponse.IdSolicitud}</ModalHeader>
                <ModalBody>
                    <form>
                        <div className="mb-3">                            
                            <label className="form-label">Cliente</label>
                            <select className="form-control" disabled value={solicitudResponse && solicitudResponse.IdCliente}>
                                <option value="0">Seleccione...</option>
                                {cliente.map(cliente => (
                                    <option key={cliente.IdCliente} value={cliente.IdCliente}>{cliente.NombreCliente}</option>
                                ))}
                            </select>
                        </div>
                        <div className="mb-3">
                            <label className="form-label">Tipo de Atención</label>
                            <select className="form-control" disabled value={solicitudResponse && solicitudResponse.TipoAtencion}>
                                <option value="0">Seleccione...</option>
                                {tipoAtencion.map(tipoAtencion => (
                                    <option key={tipoAtencion.IdParametro} value={tipoAtencion.IdParametro}>{tipoAtencion.NombreParametro}</option>
                                ))}
                            </select>
                        </div>
                        <div className="mb-3">
                            <label className="form-label">Tipo de Servicio</label>
                            <select className="form-control" disabled value={solicitudResponse && solicitudResponse.IdServicio}>
                                <option value="0">Seleccione...</option>
                                {servicio.map(servicio => (
                                    <option key={servicio.IdServicio} value={servicio.IdServicio}>{servicio.NombreServicio}</option>
                                ))}
                            </select>
                        </div>
                        <div className="mb-3">                            
                            <label className="form-label">Descripción de Solicitud</label>
                            <textarea className="form-control" type="textarea" readOnly value={solicitudResponse && solicitudResponse.DescripcionSolicitud} />
                        </div>
                        <div className="mb-3">
                        <label className="form-label">Estado de la Solicitud</label>
                            <select className="form-control" disabled value={solicitudResponse && solicitudResponse.EstadoSolicitud}>
                                <option value="0">Seleccione...</option>
                                {estadoSolicitud.map(estadoSolicitud => (
                                    <option key={estadoSolicitud.IdParametro} value={estadoSolicitud.IdParametro}>{estadoSolicitud.NombreParametro}</option>
                                ))}
                            </select>
                        </div>
                        <div className="mb-3">
                            <label className="form-label">Asignar Empleado</label>
                            <select className="form-control" disabled value={solicitudResponse && solicitudResponse.IdEmpleado}>
                                <option value="0">Seleccione...</option>
                                {empleado.map(empleado => (
                                    <option key={empleado.IdEmpleado} value={empleado.IdEmpleado}>{empleado.Nombre} {empleado.Apellidos}</option>
                                ))}
                            </select>
                        </div>
                        <div className="mb-3">
                            <label className="form-label">Fecha de Atención</label>
                            <input className="form-control" type="text" readOnly value={solicitudResponse && Moment(solicitudResponse.FechaAtencion).format('DD-MM-YYYY')} />
                        </div>
                        <div className="mb-3">
                        <label className="form-label">Estado de Atención</label>
                            <select className="form-control" disabled value={solicitudResponse && solicitudResponse.EstadoAtencion}>
                                <option value="0">Seleccione...</option>
                                {estadoAtencion.map(estadoAtencion => (
                                    <option key={estadoAtencion.IdParametro} value={estadoAtencion.IdParametro}>{estadoAtencion.NombreParametro}</option>
                                ))}
                            </select>
                        </div>
                    </form>
                </ModalBody>
                <ModalFooter>
                <button className="btn btn-success" onClick={verModalMostrar}>Cerrar</button>
                </ModalFooter>
            </Modal>
            {/* Modal Eliminar*/}
            <Modal isOpen={modalEliminar}>
                <ModalHeader>
                    Eliminar Servicio
                </ModalHeader>
                <ModalBody>
                    <form>
                        <input className="form-control" type="hidden" {...register("IdEmpleado", { required: true, value:solicitudResponse && solicitudResponse.IdSolicitud })} />
                    </form>
                    Está seguro de eliminar el registro?
                </ModalBody>
                <ModalFooter>
                    <button className="btn btn-warning" onClick={handleSubmit(eliminarSolicitud)}>Sí</button>
                    <button className="btn btn-danger" onClick={verModalEliminar}>Cerrar</button>
                </ModalFooter>
            </Modal>
            {/* Modal Respuesta*/}
            <Modal isOpen={modalRespuesta}>
                <ModalHeader>{tipomensaje}</ModalHeader>
                <ModalBody>{mensaje}</ModalBody>
                <ModalFooter>
                    <button className="btn btn-success" onClick={vermodalRespuesta}>Cerrar</button>
                </ModalFooter>
            </Modal>
        </>
    )
}

export default Solicitudes;