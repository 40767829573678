import React, { useEffect, useState } from 'react'
import { Modal, ModalBody, ModalHeader, ModalFooter } from 'reactstrap'
import { useForm } from 'react-hook-form'
import edit from '../../../images/icons/edit.png'
import nuevo from '../../../images/icons/new.png'
import view from '../../../images/icons/view.png'
import borrar from '../../../images/icons/delete.png'
import axios from 'axios'
import { Helmet } from 'react-helmet'
import Moment from 'moment'
import Cookies from 'universal-cookie'
import MaterialTable from 'material-table'

const Empleados = () => {

    const baseURL = "http://apiv2.pyrmultimediasac.com/empleado";
    const distURL = "http://apiv2.pyrmultimediasac.com/distrito/listarDistrito";
    const docURL = "http://apiv2.pyrmultimediasac.com/parametro/listarTipoDocumento";
    const cookie = new Cookies();

    const { register, handleSubmit, formState: { errors } } = useForm()
    const [empleado, setEmpleado] = useState([])
    const [empleadoResponse, setEmpleadoResponse] = useState([])
    const [empleadoRequest] = useState({})
    const [distrito, setDistrito] = useState([])
    const [tipoDocumento, setTipoDocumento] = useState([])
    const [mensaje, setMensaje] = useState([])
    const [tipomensaje, setTipoMensaje] = useState([])
    const [modalEditar, setModalEditar] = useState(false)
    const [modalNuevo, setModalNuevo] = useState(false)
    const [modalMostrar, setModalMostrar] = useState(false)
    const [modalEliminar, setModalEliminar] = useState(false)
    const [modalRespuesta, setModalRespuesta] = useState(false)

    const verModalEditar = () => setModalEditar(!modalEditar)
    const verModalNuevo = () => setModalNuevo(!modalNuevo)
    const verModalMostrar = () => setModalMostrar(!modalMostrar)
    const verModalEliminar = () => setModalEliminar(!modalEliminar)
    const vermodalRespuesta = () => setModalRespuesta(!modalRespuesta)

    const columns=[
        {title: 'Número Registro', field: 'NumeroRegistro'},
        {title: 'Nombre', field: 'Nombre'},
        {title: 'Apellidos', field: 'Apellidos'},
        {title: 'Teléfono', field: 'TelefonoEmpleado'},
        {title: 'Correo', field: 'CorreoEmpleado'},
        {title: 'Fecha de Ingreso', field: 'FechaIngreso', type: 'date'}
    ]    

    const getDistritos = async () => {
        await axios.get(distURL)
            .then(response => {
                setDistrito(response.data);
            }).catch(error => {
                console.log(error);
            })
    }

    const getTipoDocumento = async () => {
        await axios.get(docURL)
            .then(response => {
                setTipoDocumento(response.data);
            }).catch(error => {
                console.log(error);
            })
    }

    const listaEmpleados = async () => {
        await axios.get(baseURL + "/listarEmpleado")
            .then(response => {
                setEmpleado(response.data.Resultado);
            }).catch(error => {
                console.log(error);
            })

    }

    const insertarEmpleado = async (data) => {
        delete data.IdEmpleado
        data.UsuarioCreacion = cookie.get('lusuario')
        data.FechaCreacion = Moment().format()
        await axios.post(baseURL + "/insertarEmpleado", data)
            .then(response => {
                setTipoMensaje(response.data.TipoMensaje)
                setMensaje(response.data.RespuestaExito)
                verModalNuevo()
                vermodalRespuesta()
                listaEmpleados()
            }).catch(error => {
                setTipoMensaje(error.data.TipoMensaje)
                setMensaje(error.data.RespuestaError)
                console.log(error.data.Respuesta)
                vermodalRespuesta()
            })
    }

    const obtenerEmpleado = async (data,caso) => {
        empleadoRequest.IdEmpleado = data.IdEmpleado
        await axios.post(baseURL + "/obtenerEmpleado", empleadoRequest)
            .then(response => {    
                setEmpleadoResponse(response.data.Resultado[0])            
                if (caso==="ver") {                    
                    verModalMostrar()                    
                }else if(caso==="editar"){                
                    verModalEditar()
                }else{
                    verModalEliminar()
                }
            }).catch(error => {
                setTipoMensaje(error.data.TipoMensaje)
                setMensaje(error.data.RespuestaError)
                console.log(error.data.Respuesta)
                vermodalRespuesta()
            })
    }

    const actualizarEmpleado = async (data) => {
        data.UsuarioModificacion = cookie.get('lusuario')
        data.FechaModificacion = Moment().format()
        console.log(data)
        await axios.put(baseURL + "/actualizarEmpleado", data)
            .then(response => {
                setTipoMensaje(response.data.TipoMensaje)
                setMensaje(response.data.RespuestaExito)
                verModalEditar()
                vermodalRespuesta()
                listaEmpleados()
            }).catch(error => {
                setTipoMensaje(error.data.TipoMensaje)
                setMensaje(error.data.RespuestaError)
                console.log(error.data.Respuesta)                
                vermodalRespuesta()
            })
    }

    const eliminarEmpleado = async (data) => {
        empleadoRequest.UsuarioModificacion = cookie.get('lusuario')
        empleadoRequest.FechaModificacion = Moment().format()
        empleadoRequest.IdEmpleado = data.IdEmpleado
        console.log(empleadoRequest)
        await axios.post(baseURL+"/eliminarEmpleado", empleadoRequest)
            .then(response=>{                
                setTipoMensaje(response.data.TipoMensaje)
                setMensaje(response.data.RespuestaExito)
                verModalEliminar()
                vermodalRespuesta()
                listaEmpleados()
            }).catch(error =>{
                setTipoMensaje(error.data.TipoMensaje)
                setMensaje(error.data.RespuestaError)
                vermodalRespuesta()
            })
    }


    useEffect(() => {
        listaEmpleados();
        getDistritos();
        getTipoDocumento();
    }, [])

    return (
        <>
            <Helmet>
                <title>P&R Multimedia | Lista de Empleados</title>
            </Helmet>
            <div className="content-wrapper">
                <section className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1>Empleados</h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item">Home</li>
                                    <li className="breadcrumb-item active">Lista de empleados</li>
                                </ol>
                            </div>
                        </div>
                    </div>{/* /.container-fluid */}
                </section>
                <section className="content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                {/* /.card */}
                                <div className="card">
                                    {/* /.card-header */}
                                    <div className="card-body">
                                        <a href="javascript:void(0)" onClick={verModalNuevo} title="Nuevo Empleado">
                                            <img src={nuevo}></img>{' '}
                                        </a>
                                        <hr />
                                        <MaterialTable
                                            columns={columns}
                                            data={empleado}
                                            title="Empleados"
                                            actions={[
                                                {
                                                    icon: ()=><img src={edit}></img>,
                                                    tooltip: 'Editar',
                                                    onClick: (event, rowdata)=>obtenerEmpleado(rowdata,"editar")
                                                },                                                
                                                {
                                                    icon: ()=><img src={view}></img>,
                                                    tooltip: 'Ver',
                                                    onClick: (event, rowdata)=>obtenerEmpleado(rowdata,"ver")
                                                },
                                                {
                                                    icon: ()=><img src={borrar}></img>,
                                                    tooltip: 'Eliminar',
                                                    onClick: (event, rowdata)=>obtenerEmpleado(rowdata,"eliminar")
                                                }
                                            ]}
                                            options={{
                                                paging: false,
                                                actionsColumnIndex: -1,
                                                header: { position: 'sticky', top: 0 }
                                            }}
                                            localization={{
                                                header: { actions: 'Acciones' },
                                                toolbar:{ searchTooltip: 'Buscar', searchPlaceholder: 'Buscar' },
                                                pagination:{labelDisplayedRows:'{from}-{to} de {count} filas',labelRowsSelect:'Filas',firstTooltip:'Primera Página',previousTooltip:'Página anterior', lastTooltip:'Última Página', nextTooltip:'Página siguiente'}
                                            }}
                                        />                                                                                    
                                    </div>
                                    {/* /.card-body */}
                                </div>
                                {/* /.card */}
                            </div>
                            {/* /.col */}
                        </div>
                        {/* /.row */}
                    </div>
                    {/* /.container-fluid */}
                </section>
            </div>
            {/* Modal Insertar*/}
            <Modal isOpen={modalNuevo}>
                <ModalHeader>Nuevo Empleado</ModalHeader>
                <ModalBody>
                    <form>
                        <div className="mb-3">
                            <label className="form-label">Nombre</label>
                            <input className="form-control" type="text" {...register("Nombre", { required: true, value:"" })} />
                            <input className="form-control" type="hidden" {...register("IdEmpleado", { required: true, value:0 })} />
                            {errors.Nombre && <span className="text-danger">El nombre es requerido</span>}
                        </div>
                        <div className="mb-3">
                            <label className="form-label">Apellido Paterno</label>
                            <input className="form-control" type="text" {...register("ApellidoPaterno", { required: true, value:""  })} />
                            {errors.ApellidoPaterno && <span className="text-danger">El apellido paterno es requerido</span>}
                        </div>
                        <div className="mb-3">
                            <label className="form-label">Apellido Materno</label>
                            <input className="form-control" type="text" {...register("ApellidoMaterno",{ value:"" })} />
                        </div>
                        <div className="mb-3">
                            <label className="form-label">Tipo de Documento de Identidad</label>
                            <select className="form-control" {...register("TipoDocumento", { valueAsNumber: true, value:0 })}>
                                <option value="0">Seleccione...</option>
                                {tipoDocumento.map(tipodocumento => (
                                    <option key={tipodocumento.IdParametro} value={tipodocumento.IdParametro}>{tipodocumento.NombreParametro}</option>
                                ))}
                            </select>
                        </div>
                        <div className="mb-3">
                            <label className="form-label">Número de documento de identidad</label>
                            <input className="form-control" type="number" {...register("NumeroDocumento", { required: true, pattern: /^[0-9]{8}$/ , value:"" })} />
                            {errors.NumeroDocumento && <span className="text-danger">El documento es requerido</span>}
                        </div>
                        <div className="mb-3">
                            <label className="form-label">Distrito</label>
                            <select className="form-control" {...register("IdDistrito", { required: true, value:0 })}>
                                <option value="0">Seleccione...</option>
                                {distrito.map(distrito => (
                                    <option key={distrito.IdDistrito} value={distrito.IdDistrito}>{distrito.NombreDistrito}</option>
                                ))}
                            </select>
                        </div>
                        <div className="mb-3">
                            <label className="form-label">Dirección</label>
                            <input className="form-control" {...register("DireccionEmpleado", { required: true, value:""  })} />
                            {errors.DireccionEmpleado && <span className="text-danger">La dirección es requerida</span>}
                        </div>
                        <div className="mb-3">
                            <label className="form-label">Número de teléfono</label>
                            <input className="form-control" type="number" maxLength="9" {...register("TelefonoEmpleado", { pattern: /^[0-9]{9}$/, value:"" })} />
                            {errors.TelefonoEmpleado && <span className="text-danger">Telefono requerido</span>}
                        </div>
                        <div className="mb-3">
                            <label className="form-label">Correo electrónico</label>
                            <input className="form-control" type="email" {...register("CorreoEmpleado", { required: true, pattern: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/, value:""  })} />
                            {errors.CorreoEmpleado && <span className="text-danger">El correo es requerido o es incorrecto</span>}
                        </div>
                    </form>
                </ModalBody>
                <ModalFooter>
                    <button type="sub" className="btn btn-primary" onClick={handleSubmit(insertarEmpleado)}>Insertar</button>
                    <button className="btn btn-danger" onClick={verModalNuevo}>Cancelar</button>
                </ModalFooter>
            </Modal>
            {/* Modal Editar*/}
            <Modal isOpen={modalEditar}>
                <ModalHeader>Editar Empleado</ModalHeader>
                <ModalBody>
                    <form>                        
                        <div className="mb-3">
                            <label className="form-label">Nombre</label>
                            <input className="form-control" {...register("Nombre", { required: true, value: empleadoResponse && empleadoResponse.Nombre})} />
                            <input className="form-control" type="hidden" {...register("IdEmpleado", { required: true, value:empleadoResponse && empleadoResponse.IdEmpleado })} />
                            {errors.Nombre && <span className="text-danger">El nombre es requerido</span>}
                        </div>
                        <div className="mb-3">
                            <label className="form-label">Apellido Paterno</label>
                            <input className="form-control" {...register("ApellidoPaterno", { required: true, value:empleadoResponse && empleadoResponse.ApellidoPaterno })} />
                            {errors.ApellidoPaterno && <span className="text-danger">El apellido paterno es requerido</span>}
                        </div>
                        <div className="mb-3">
                            <label className="form-label">Apellido Materno</label>
                            <input className="form-control" {...register("ApellidoMaterno",{value:empleadoResponse && empleadoResponse.ApellidoMaterno})}/>
                        </div>
                        <div className="mb-3">
                            <label className="form-label">Tipo de Documento de Identidad</label>
                            <select className="form-control" {...register("TipoDocumento", { valueAsNumber: true, value: empleadoResponse && empleadoResponse.TipoDocumento})}>
                                <option value="0">Seleccione...</option>
                                {tipoDocumento.map(tipodocumento => (
                                    <option key={tipodocumento.IdParametro} value={tipodocumento.IdParametro}>{tipodocumento.NombreParametro}</option>
                                ))}
                            </select>
                        </div>
                        <div className="mb-3">
                            <label className="form-label">Número de documento de identidad</label>
                            <input className="form-control" type="number" {...register("NumeroDocumento", { required: true, pattern: /^[0-9]{8}$/, value:empleadoResponse && empleadoResponse.NumeroDocumento })} />
                            {errors.NumeroDocumento && <span className="text-danger">El documento es requerido</span>}
                        </div>
                        <div className="mb-3">
                            <label className="form-label">Distrito</label>
                            <select className="form-control" {...register("IdDistrito", { required: true,value: empleadoResponse && empleadoResponse.IdDistrito})}>
                                <option value="0">Seleccione...</option>
                                {distrito.map(distrito => (
                                    <option key={distrito.IdDistrito} value={distrito.IdDistrito}>{distrito.NombreDistrito}</option>
                                ))}
                            </select>
                        </div>
                        <div className="mb-3">
                            <label className="form-label">Dirección</label>
                            <input className="form-control" {...register("DireccionEmpleado", { required: true, value:empleadoResponse && empleadoResponse.DireccionEmpleado })} />
                            {errors.DireccionEmpleado && <span className="text-danger">La dirección es requerida</span>}
                        </div>
                        <div className="mb-3">
                            <label className="form-label">Número de teléfono</label>
                            <input className="form-control" type="number" maxLength="9" {...register("TelefonoEmpleado", { pattern: /^[0-9]{9}$/, value:empleadoResponse && empleadoResponse.TelefonoEmpleado })} />
                            {errors.TelefonoEmpleado && <span className="text-danger">Telefono requerido</span>}
                        </div>
                        <div className="mb-3">
                            <label className="form-label">Correo electrónico</label>
                            <input className="form-control" type="email" {...register("CorreoEmpleado", { required: true, pattern: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/, value: empleadoResponse && empleadoResponse.CorreoEmpleado})} />
                            {errors.CorreoEmpleado && <span className="text-danger">El correo es requerido o es incorrecto</span>}
                        </div>
                    </form>
                </ModalBody>
                <ModalFooter>
                    <button className="btn btn-primary" onClick={handleSubmit(actualizarEmpleado)}>Actualizar</button>{' '}
                    <button className="btn btn-danger" onClick={verModalEditar}>Cancelar</button>
                </ModalFooter>
            </Modal>
            {/* Modal Mostrar*/}
            <Modal isOpen={modalMostrar}>
                <ModalHeader>Empleado Nº: {empleadoResponse && empleadoResponse.IdEmpleado}</ModalHeader>
                <ModalBody>
                    <form>
                        <div className="mb-3">
                            <label className="form-label">Nombre</label>
                            <input className="form-control" readOnly value={empleadoResponse && empleadoResponse.Nombre} />
                        </div>
                        <div className="mb-3">
                            <label className="form-label">Apellido Paterno</label>
                            <input className="form-control" readOnly value={empleadoResponse && empleadoResponse.ApellidoPaterno} />
                        </div>
                        <div className="mb-3">
                            <label className="form-label">Apellido Materno</label>
                            <input className="form-control" readOnly value={empleadoResponse && empleadoResponse.ApellidoMaterno} />
                        </div>
                        <div className="mb-3">
                            <label className="form-label">Tipo de Documento de Identidad</label>
                            <select className="form-control" disabled value={empleadoResponse && empleadoResponse.TipoDocumento} >
                                <option value="0">Seleccione...</option>
                                {tipoDocumento.map(tipodocumento => (
                                    <option key={tipodocumento.IdParametro} value={tipodocumento.IdParametro}>{tipodocumento.NombreParametro}</option>
                                ))}
                            </select>
                        </div>
                        <div className="mb-3">
                            <label className="form-label">Número de documento de identidad</label>
                            <input className="form-control" type="number" readOnly value={empleadoResponse && empleadoResponse.NumeroDocumento} />
                        </div>
                        <div className="mb-3">
                            <label className="form-label">Distrito</label>
                            <select className="form-control" disabled value={empleadoResponse && empleadoResponse.IdDistrito} >
                                <option value="0">Seleccione...</option>
                                {distrito.map(distrito => (
                                    <option key={distrito.IdDistrito} value={distrito.IdDistrito}>{distrito.NombreDistrito}</option>
                                ))}
                            </select>
                        </div>
                        <div className="mb-3">
                            <label className="form-label">Dirección</label>
                            <input className="form-control" readOnly value={empleadoResponse && empleadoResponse.DireccionEmpleado} />
                        </div>
                        <div className="mb-3">
                            <label className="form-label">Número de teléfono</label>
                            <input className="form-control" type="number" maxLength="9" readOnly value={empleadoResponse && empleadoResponse.TelefonoEmpleado} />
                        </div>
                        <div className="mb-3">
                            <label className="form-label">Correo electrónico</label>
                            <input className="form-control" type="email" readOnly value={empleadoResponse && empleadoResponse.CorreoEmpleado} />
                        </div>
                    </form>
                </ModalBody>
                <ModalFooter>
                    <button className="btn btn-success" onClick={verModalMostrar}>Cerrar</button>
                </ModalFooter>
            </Modal>
            {/* Modal Eliminar*/}
            <Modal isOpen={modalEliminar}>
                <ModalHeader>
                    Eliminar Empleado
                </ModalHeader>
                <ModalBody>
                    <form>
                        <input className="form-control" type="hidden" {...register("IdEmpleado", { required: true, value:empleadoResponse && empleadoResponse.IdEmpleado })} />
                    </form>
                    Está seguro de eliminar el registro?
                </ModalBody>
                <ModalFooter>
                    <button className="btn btn-warning" onClick={handleSubmit(eliminarEmpleado)}>Sí</button>
                    <button className="btn btn-danger" onClick={verModalEliminar}>Cerrar</button>
                </ModalFooter>
            </Modal>
            {/* Modal Respuesta*/}
            <Modal isOpen={modalRespuesta}>
                <ModalHeader>{tipomensaje}</ModalHeader>
                <ModalBody>{mensaje}</ModalBody>
                <ModalFooter>
                    <button className="btn btn-success" onClick={vermodalRespuesta}>Cerrar</button>
                </ModalFooter>
            </Modal>
        </>
    )
}

export default Empleados;